import { useForm } from 'react-hook-form'

import { IdsMainButton, IdsLoading } from '@ids/react'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { Carousel, CarouselItem } from '@mobi/ds'
import { If, Input } from 'components'

import './Hero.scss'
import { BANNERS, SETTINGS_GLIDE } from './constants'
import { useHero } from './hooks'

const Hero = () => {
  const {
    leadData: { cpf },
    changeLeadData
  } = useLead()

  const initialValues = {
    cpf: cpf ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { isButtonLoading, onSubmit } = useHero({ changeLeadData })

  return (
    <>
      <div className="lp-hero__seo-wrapper">
        <h1 className="lp-hero__seo-text">
          Consignado, empréstimo e crédito pessoal Itaú
        </h1>
      </div>
      <section className="lp-hero">
        <div className="lp-hero__container">
          <Carousel
            indentifier="hero"
            variant="banner"
            bullets
            arrows
            enableFocusActiveSlide={false}
            options={SETTINGS_GLIDE}
            className="lp-hero__carousel"
          >
            {BANNERS.map(({ title, description }, index) => (
              <CarouselItem key={index}>
                <div className="lp-hero__content">
                  <h2 className="lp-hero__title">{title}</h2>
                  <p className="lp-hero__description">{description}</p>
                </div>
                <div className={`lp-hero__image lp-hero__image-${index}`}></div>
              </CarouselItem>
            ))}
          </Carousel>
          <div className="lp-hero__submitter container">
            <form
              className="lp-hero__form ids-card"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="lp-hero__submitter-title">
                Descubra agora o empréstimo ideal para você
              </h2>
              <Input.Cpf
                className="lp-hero__submitter-document"
                label="CPF"
                id="cpf"
                data-cy="cy-input-cpf"
                name="cpf"
                autoComplete="off"
                control={control}
              />
              <IdsMainButton
                full={true}
                type="submit"
                disabled={!isValid || isButtonLoading}
                aria-label="simular empréstimo consignado agora"
              >
                <If
                  condition={isButtonLoading}
                  renderIf={
                    <IdsLoading
                      aria-live="polite"
                      aria-label="aguarde, estamos enviando seus dados..."
                      size="XS"
                      show={true}
                    />
                  }
                  renderElse={'Simular agora'}
                />
              </IdsMainButton>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
