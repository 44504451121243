import { useState } from 'react'

import { IdsIcon, IdsContextualButton, IdsMainButton } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import analytics from '@mobi/libraries/analytics'
import classnames from 'classnames'
import { goTo } from 'utils'

import './Product.scss'

const Product = ({
  variant,
  title,
  description,
  cards,
  id,
  detail,
  filterDetail
}) => {
  const [filteredCards, setFilteredCards] = useState(cards.slice(0, 2))
  const [nextOpenedIndex, setNextOpenedIndex] = useState(4)

  return (
    <section
      id={id}
      className={classnames('lp-product', {
        'lp-product--gray': variant === 'gray',
        'lp-product--white': variant === 'white'
      })}
    >
      <div className="lp-product__container container">
        <div className="lp-product__info">
          <h2 className="lp-product__title">{title}</h2>
          <p className="lp-product__description">{description}</p>
          <IdsMainButton
            className="lp-product__button"
            onClick={() => {
              analytics.track('buttonClick', {
                currentPage: 'home-lp-shopping',
                detail: detail
              })
              goTo('basic-data')
            }}
            aria-label={`simular empréstimo ${title} agora`}
          >
            Simular agora
          </IdsMainButton>
        </div>
        <div>
          <ul className="lp-product__list">
            {filteredCards.map((card) => (
              <li className="lp-product__item ids-card" key={card.title}>
                <h3 className="lp-product__item-title">{card.title}</h3>
                <ul className="lp-product__benefits">
                  {card.benefits.map((benefit) => (
                    <li className="lp-product__benefit" key={benefit.text}>
                      <IdsIcon className="lp-product__benefit-image">
                        {benefit.icon}
                      </IdsIcon>
                      <p className="lp-product__benefit-text">{benefit.text}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <If
            condition={filteredCards.length < cards.length}
            renderIf={
              <IdsContextualButton
                className="lp-product__button-more"
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'home-lp-shopping',
                    detail: filterDetail
                  })
                  setFilteredCards(cards.slice(0, nextOpenedIndex))
                  setNextOpenedIndex((state) => state + 2)
                }}
              >
                Mais empréstimos
              </IdsContextualButton>
            }
            renderElse={
              <If
                condition={filteredCards.length > 2}
                renderIf={
                  <IdsContextualButton
                    className="lp-product__button-more"
                    onClick={() => {
                      setFilteredCards(cards.slice(0, 2))
                      setNextOpenedIndex(4)
                      document.getElementById(id).scrollIntoView()
                    }}
                  >
                    Menos empréstimos
                  </IdsContextualButton>
                }
              />
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Product
